<template>
    <b-form @submit.prevent inline>
        <b-row class="align-items-center justify-content-center mt-3 pb-4">
            <div class="col-auto mt-3 mt-md-auto pe-lg-5">
                <b-form-group
                    id="amount-input"
                    class="row"
                >
                    <b-row class="align-items-center justify-content-start">
                        <div class="col-auto">
                            <label class="col-form-label no-wrap p-0 font-size-18" for="amount">{{ $t('invoices.amount') }}:
                                <i v-b-tooltip.hover.top="$t('invoices.minimum')" class="info fas fa-info-circle me-1"></i>
                            </label>
                        </div>
                        <b-col>
                            <b-form-input ref="amount_input" class="w-auto" id="amount" name="amount" :type="type" size="sm" v-model="amount" min="5000" @focus="type = 'number'" :placeholder="amountPlaceholder" @blur="currency"></b-form-input>
                        </b-col>
                    </b-row>
                </b-form-group>
            </div>
            <div class="col-auto mt-3 mt-md-auto">
                <b-form-group
                    id="payment-type-input"
                    class="row"
                >
                    <b-row class="align-items-center justify-content-start">
                        <div class="col-auto">
                            <label class="col-form-label no-wrap p-0 font-size-18" for="payment-type">{{ $t('invoices.payment_type') }}: </label>
                        </div>
                        <b-col>
                            <b-form-select id="payment-type" class="form-control form-select form-select-sm w-auto" name="payment-type" v-model="payment_type">
                                <template #first>
                                    <b-form-select-option value="T" selected>{{ $t('invoices.transfer') }}</b-form-select-option>
                                    <b-form-select-option value="C" selected>{{ $t('invoices.card') }}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                    </b-row>
                </b-form-group>
            </div>
            <div class="col-12 col-md-auto mt-3 mt-md-auto text-end ps-lg-5">
                <b-button size="sm" variant="primary" block class="w-100 btn-lg btn-rounded px-3" @click="deposit">
                    <i class="fas fa-money-check-alt me-2"></i>
                    <span>{{ $t('invoices.pay_in') }}</span>
                </b-button>
            </div>
        </b-row>
    </b-form>
</template>

<script>
export default {
    name: "deposit-form",

    data: function () {
        return {
            amount: 10000,
            payment_type: 'T',
            type: 'text',
            minimum: 5000
        }
    },

    computed: {
        amountPlaceholder: function() {
            return this.$currencyFormatter.format(5000)
        }
    },

    methods: {
        currency: function (evt) {
            console.log('RUN')
            this.type = 'text'
            if (evt.target.value < this.minimum) {
                evt.target.value = this.amount = this.minimum
            }
            this.$nextTick(() => {
                evt.target.value = this.$currencyFormatter.format(evt.target.value)
            })
        },

        deposit: function () {
            this.$store.dispatch('invoice/deposit', { amount: this.amount, payment_type: this.payment_type }).then(response => {
                if (this.payment_type === 'C') {
                    this.$router.push({name: 'stripe', params: { clientSecret: response.client_secret, pK: response.public_key, amount: response.amount, vat: response.vat, gross: response.gross, stripeId: response.id }});
                }
            });
        },
    },

    mounted() {
        this.$refs['amount_input'].focus()
        this.$refs['amount_input'].blur()
    }
}
</script>

<style scoped>
    .info {
        vertical-align: super;
        font-size: 10px;
    }

    #amount::placeholder {
        opacity: .5;
    }
</style>
